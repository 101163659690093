<template>
  <v-snackbar
    :value="visible"
    :color="color"
    bottom
    left
    @input="hide"
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="hide">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MainNotify',
  computed: {
    ...mapGetters('ui', {
      notification: 'notification',
    }),
    visible () {
      return this.notification.visible
    },
    message () {
      return this.notification.message
    },
    color () {
      return this.notification.success
        ? 'success'
        : 'error'
    },
  },
  methods: {
    ...mapActions('ui', {
      hide: 'hideNotification',
    }),
  },
}
</script>
